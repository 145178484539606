<template>
	<div>
		<a-modal v-model:visible="visible" width="800px" @cancel=close>
			<template #title>
				<modalHeader title="新增项目"></modalHeader>
			</template>
			<template #footer>
				<a-button @click="close">取消</a-button>
				<a-button @click="onSubmitProjectBasic(0)">提交</a-button>
				<a-button @click="onSubmitProjectBasic(1)" type="primary">下一步</a-button>
			</template>

			<a-form :model="formProjectInfo" ref="formProjectInfoRef" :rules="formProjectInfoRules">
				<h3 style="text-align: center;">项目基本情况</h3>
				<a-row :gutter="24">
					<a-col :span="12">
						<a-form-item label="项目名称" name="name">
							<a-input placeholder="请输入项目名称" v-model:value="formProjectInfo.name"></a-input>
						</a-form-item>
						<a-form-item label="主管单位" name="mangerUnitId">
							<a-select v-model:value="formProjectInfo.mangerUnitId" placeholder="请选择单位">
								<a-select-option v-for="(item, index) in $store.state.count.mangerList" :key="item.id"
									:value="item.id">{{ item.name }}</a-select-option>
								<!-- <a-select-option :value="myUnitData.id">{{myUnitData.name}}</a-select-option> -->
							</a-select>
						</a-form-item>
						<a-form-item label="主管单位联系人电话" name="mangerUserPhone">
							<a-input placeholder="请输入主管单位联系人电话" v-model:value="formProjectInfo.mangerUserPhone">
							</a-input>
						</a-form-item>
					</a-col>
					<a-col :span="12">
						<a-form-item label="项目分类" name="classify" required>
							<!-- <a-select v-model:value="formProjectInfo.classify" placeholder="请选择分类" :disabled="isModify">
                            	<a-select-option :value="1">固定资产</a-select-option>
                            	<a-select-option :value="2">无形资产</a-select-option>
                            	<a-select-option :value="3">水利</a-select-option>
                            	<a-select-option :value="4">交通</a-select-option>
                            	<a-select-option :value="5">市政</a-select-option>
                            	<a-select-option :value="6">其他</a-select-option>
                            	<a-select-option v-for="(item, index) in $store.state.count.unitList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
                            </a-select> -->
							<a-cascader v-model:value="formProjectInfo.classify" :options="options" placeholder="请选择分类" />
						</a-form-item>
						<a-form-item label="主管单位联系人" name="mangerUsername">
							<a-input placeholder="请输入主管单位联系人姓名" v-model:value="formProjectInfo.mangerUsername">
							</a-input>
						</a-form-item>
						<a-form-item label="启动时间" name="bootTime" required>
							<a-date-picker :disabled-date="disabledDate" placeholder="同意启动的会议或文件时间" v-model:value="formProjectInfo.bootTime"
								style="width: 280px;"></a-date-picker>
						</a-form-item>
					</a-col>
				</a-row>
				<a-form-item label="项目基本情况" name="description">
					<a-textarea v-model:value="formProjectInfo.description" placeholder="请填写项目主要建设内容" :rows="4" />
				</a-form-item>
				<h3 style="text-align: center;">实施单位信息</h3>
				<a-row :gutter="24">
					<a-col :span="12">
						<a-form-item label="实施单位" required name="executeUnitId">
							<!-- <a-select v-model="formProjectInfo.conductUnit" placeholder="请选择主管单位">
                                <a-select-option value="单位1">单位1</a-select-option>
                                <a-select-option value="单位2">单位2</a-select-option>
                                <a-select-option value="单位3">单位3</a-select-option>
                            </a-select> -->
							<a-select v-model:value="formProjectInfo.executeUnitId" disabled placeholder="请选择实施单位">
								<a-select-option :value="myUnitData.id">{{myUnitData.name}}</a-select-option>
								<!-- <a-select-option v-for="(item, index) in $store.state.count.unitList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option> -->
							</a-select>
						</a-form-item>
						<a-form-item label="项目负责人姓名" name="headUsername">
							<a-input v-model:value="formProjectInfo.headUsername" placeholder="请输入项目负责人姓名"></a-input>
						</a-form-item>
						<a-form-item label="分管领导姓名" name="leaderUsername">
							<a-input v-model:value="formProjectInfo.leaderUsername" placeholder="请输入分管领导姓名"></a-input>
						</a-form-item>
					</a-col>
					<a-col :span="12">
						<a-form-item></a-form-item>
						<a-form-item label="项目负责人电话" name="headUserPhone">
							<a-input v-model:value="formProjectInfo.headUserPhone" placeholder="请输入项目负责人电话"></a-input>
						</a-form-item>
						<a-form-item label="分管领导电话" name="leaderPhone">
							<a-input v-model:value="formProjectInfo.leaderPhone" placeholder="请输入分管领导电话"></a-input>
						</a-form-item>
					</a-col>
				</a-row>
			</a-form>


		</a-modal>
	</div>
</template>

<script>
	import modalHeader from '@/components/modalHeader/index.vue';
	import {
		postProjectBasic,
		getMyUnit
	} from '@/service/modules/project.js'
	export default {
		components: {
			modalHeader
		},
		data() {
			return {
				myUnitData: {},
				visible: false,
				options: [{
						value: "1",
						label: '固定资产',
						children: []
					},
					{
						value: "2",
						label: '公共基础设施',
						children: [{
							value: "3",
							label: '水利'
						}, {
							value: "4",
							label: '交通'
						}, {
							value: "5",
							label: '市政'
						}, {
							value: "6",
							label: '其它'
						}],
					},
					{
						value: "7",
						label: '费用支出',
						children: []
					}
				],
				// 基本信息录入---表单校验规则
				formProjectInfoRules: {
					name: [{
						required: true,
						message: '请输入项目名称'
					}],
					// classify:[{required:true,message:'选择项目分类'}],
					mangerUnitId: [{
						required: true,
						message: '请选择主管单位'
					}],
					mangerUserPhone: [{
						required: true,
						message: '请输入正确的手机号',
						pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
						trigger: 'blur'
					}],
					mangerUsername: [{
						required: true,
						message: '请输入主管单位联系人',
						trigger: 'blur'
					}, {
						min: 1,
						max: 20,
						message: '请输入正确的姓名',
						trigger: 'blur'
					}],
					description: [{
						required: true,
						message: '请输入项目主要的建设内容'
					}, {
						min: 10,
						message: '至少10个字',
						trigger: 'blur'
					}],
					headUsername: [{
						required: true,
						message: '请输入项目负责人姓名'
					}, {
						min: 1,
						max: 20,
						message: '请输入正确的姓名',
						trigger: 'blur'
					}],
					leaderUsername: [{
						required: true,
						message: '请输入分管领导姓名'
					}, {
						min: 1,
						max: 20,
						message: '请输入正确的姓名',
						trigger: 'blur'
					}],
					headUserPhone: [{
						required: true,
						message: '请输入正确的手机号',
						pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
						trigger: 'blur'
					}],
					leaderPhone: [{
						required: true,
						message: '请输入正确的手机号',
						pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
						trigger: 'blur'
					}]
				},
				formProjectInfo: {
					// executeUnitId: 3
					// classify: ["2", "5"]
				}
			}
		},
		mounted() {
			// this.formProjectInfo.executeUnitId = 3
			// this.getMyUnitData()
		},
		methods: {
			async getMyUnitData() {
				try {
					let ret = await getMyUnit({})
					if (ret.code === 200) {
						this.myUnitData = ret.data
						this.formProjectInfo.executeUnitId = ret.data.id
						this.formProjectInfo.mangerUnitId = ret.data.id
						// this.formProjectInfo.executeUnitId = ret.data.id
						// console.log("uni",this.myUnitData)
					}
				} catch (e) {
					//TODO handle the exception
				}
			},
			onSubmitProjectBasic(next) { // 提交基本信息
				// return new Promise(() => {
				this.$refs.formProjectInfoRef.validate().then(async () => {
					let postData = JSON.parse(JSON.stringify(this.formProjectInfo));
					let tempTime = this.transDateTime(postData.bootTime)
					tempTime = new Date(tempTime)
					// postData.bootTime = tempTime.valueOf()
					postData.bootTime = this.moment(tempTime).unix();
					this.baseInfoLoading = true;
					try {
						let ret = await postProjectBasic(postData)
						this.baseInfoLoading = false;
						if (ret.code === 200) {
							this.$message.success('录入成功');
							// return ret.code
							this.$emit('onReset');
							this.close();
							if(next === 1) this.onWriteInfo(ret.data.id)
						}
					} catch (e) {
						this.baseInfoLoading = false;
					}
				})
				// })
			},
			onWriteInfo(id) { // 录入
				this.$router.push({
					name: 'projectRecord',
					query: {
						id,
						stage: 0,
					}
				})
			},
			open() {
				this.getMyUnitData();
				this.visible = true;
			},
			close() {
				this.$refs.formProjectInfoRef.clearValidate();
				this.formProjectInfo = {};
				this.visible = false;
			},
			onSubmit() {
				this.onSubmitProjectBasic(1)
			},
			disabledDate(current) {
				return current >= this.moment().endOf('day')
			}
		}
	}
</script>

<style scoped>
	.ui-formTitle {
		font-size: 15px;
		font-weight: 800;
	}
</style>
