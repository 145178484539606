<template>
	<div>
		<a-spin :spinning="loading">
			<a-row>
				<a-col :span="18">
					<a-button @click="onSearchBtn">
						<template #icon>
							<Icon icon="SearchOutlined" />
						</template>
						查询
					</a-button>
					<exportModal class="ui-exportBrn" v-permission="['pc_project_manger_export']" :type="type" :searchData="selIdsData" :condition="condition" :role="role" :isAll="1">
						<a-button>
							<template #icon>
								<Icon icon="UploadOutlined" />
							</template>
							导出
						</a-button>
					</exportModal>
					<exportModal class="ui-exportBrn" v-permission="['pc_project_manger_export']" style="margin-right: 16px;" :type="type" :searchData="searchData" :condition="condition" :role="role" :isAll="0">
						<a-button>
							<template #icon>
								<Icon icon="LogoutOutlined" />
							</template>
							导出全部
						</a-button>
					</exportModal>
					<a-button @click="onSearch">
						<template #icon>
							<Icon icon="ReloadOutlined" />
						</template>
						刷新
					</a-button>
				</a-col>
				<a-col :span="6" style="text-align: right;">
					<a-button v-permission="['pc_project_manger_add']" @click="onTask" type="primary">新增项目</a-button>
				</a-col>
			</a-row>

			<div v-show="isSearch">
				<a-form class="label-width ui-form" ref="formRef" :model="formSearch" layout="inline">
					<a-form-item class="ui-form__item" label="主管单位">
						<a-select allow-clear style="width: 190px;" v-model:value="formSearch.mangerUnitId" placeholder="请选择主管单位">
							<a-select-option v-for="(item, index) in $store.state.count.unitList" :key="item.id"
								:value="item.id">{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item class="ui-form__item" label="项目名称">
						<a-input v-model:value="formSearch.name" placeholder="请输入项目名称"></a-input>
					</a-form-item>
					<a-form-item class="ui-form__item" label="实施单位">
						<a-select allow-clear style="width: 190px;" v-model:value="formSearch.executeUnitId" placeholder="请选择实施单位">
							<a-select-option v-for="(item, index) in $store.state.count.unitList" :key="item.id"
								:value="item.id">{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item class="ui-form__item" label="施工单位">
						<a-input placeholder="请输入施工单位名称" v-model:value="formSearch.constructionCompany"></a-input>
						<!-- <a-select allow-clear style="width: 190px;" v-model="formSearch.unit">
							<a-select-option value="单位1">单位1</a-select-option>
							<a-select-option value="单位2">单位2</a-select-option>
						</a-select> -->
					</a-form-item>
					<a-form-item class="ui-form__item" label="项目分类">
						<!-- <a-select allow-clear style="width: 190px;" v-model:value="formSearch.classify" placeholder="请选择项目分类">
							<a-select-option :value="1">固定资产</a-select-option>
							<a-select-option :value="2">公共基础设施</a-select-option>
							<a-select-option :value="3">水利</a-select-option>
							<a-select-option :value="4">交通</a-select-option>
							<a-select-option :value="5">市政</a-select-option>
							<a-select-option :value="6">其它</a-select-option>
							<a-select-option :value="7">费用支出</a-select-option>
						</a-select> -->
						<a-cascader v-model:value="formSearch.classify" :options="options" placeholder="请选择分类" />
					</a-form-item>

					<a-form-item class="ui-form__item" label="是否重大重点项目">
						<a-select allow-clear style="width: 190px;" v-model:value="formSearch.isImportant" placeholder="请选择是否重大重点项目">
							<a-select-option :value="1">是</a-select-option>
							<a-select-option :value="0">否</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="项目阶段" class="ui-form__item">
						<a-select allow-clear style="width: 190px;" v-model:value="formSearch.stage" placeholder="请选择项目阶段">
							<a-select-option :value="1">建设用地储备</a-select-option>
							<a-select-option :value="2">项目立项</a-select-option>
							<a-select-option :value="3">项目招标</a-select-option>
							<a-select-option :value="4">项目施工</a-select-option>
							<a-select-option :value="5">竣工验收</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" label="启动时间">
						<a-range-picker v-model:value="clearTime" @change="onChangeFun" show-time />
					</a-form-item>
					<a-form-item class="ui-form__item" label="投资概算">
						<!-- 缺 -->
						<a-input-group>
							<a-row :gutter="8">
								<a-col :span="5">
									<a-input v-model:value="formSearch.startInvestCost"></a-input>
								</a-col>
								<a-col style="line-height: 32px;">-</a-col>
								<a-col :span="5">
									<a-input v-model:value="formSearch.endInvestCost"></a-input>
								</a-col>
								<a-col style="line-height: 32px;">元</a-col>
							</a-row>
						</a-input-group>
					</a-form-item>
					<!-- <a-form-item class="ui-form__item" label="招标价（合同价）">
						<a-input-group>
							<a-row :gutter="8">
								<a-col :span="5">
									<a-input v-model="formSearch.priceStart"></a-input>
								</a-col>
								<a-col style="line-height: 32px;">-</a-col>
								<a-col :span="5">
									<a-input v-model="formSearch.priceEnd"></a-input>
								</a-col>
								<a-col style="line-height: 32px;">元</a-col>
							</a-row>
						</a-input-group>
					</a-form-item> -->
					<a-form-item class="ui-form__item" label="工程进度">
						<!-- 缺 -->
						<a-input-group>
							<a-row :gutter="8">
								<a-col :span="5">
									<a-input v-model:value="startSchedule"></a-input>
								</a-col>
								<a-col style="line-height: 32px;">-</a-col>
								<a-col :span="5">
									<a-input v-model:value="endSchedule"></a-input>
								</a-col>
								<a-col style="line-height: 32px;">%</a-col>
							</a-row>
						</a-input-group>
					</a-form-item>
					<a-form-item class="ui-form__item">
						<a-button @click="onReset">重置</a-button>
						<a-button type="primary" @click="onSearch()">搜索</a-button>
					</a-form-item>
				</a-form>

			</div>

			<div style="margin-top:20px;">
				<div style="margin-left:20px;">单位：元</div>
				<!-- :dataList="dataList" -->
				<c-Table ref="cTable" :columns="columns" :isRequest="true" :requestFun="getDataFun"
					rowSelectionType="checkbox" :searchData="searchData" @rowSelected="rowSelected" :scroll="{ x: 'max-content' }"
					size="small" bordered draggable @changeSort="changeSort">
					<template #headerCell="{ column }">
						<template v-if="column.key === 'index'">
							<div @click="onShowSelectModal">
								<Icon icon="SettingFilled"></Icon>
							</div>
						</template>
					</template>
					<template #bodyCell="{ column, record, index, pagination }">
						<template v-if="column.key === 'index'">
							{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
						</template>
						<template v-if="column.key === 'is_important'">
							<span v-if="record.stage <=2 ">-</span>
							<span v-else>{{ record.is_important === 0 ? "否":'是'}}</span>
						</template>
						<template v-if="column.key === 'constructionCompany'">
							{{record.construction_company ? record.construction_company: '/'}}
						</template>
						<template v-if="column.key === 'projectApprovalIsPlan'">
							{{ record.projectApprovalIsPlan === '1' ? "否" : '是'}}
						</template>
						<template v-if="column.key === 'classify'">
							<div v-if="classiEcho(record.classify) === '1'">固定资产</div>
							<div v-if="classiEcho(record.classify) === '2'">公共基础设施</div>
							<div v-if="classiEcho(record.classify) === '3'">水利</div>
							<div v-if="classiEcho(record.classify) === '4'">交通</div>
							<div v-if="classiEcho(record.classify) === '5'">市政</div>
							<div v-if="classiEcho(record.classify) === '6'">其它</div>
							<div v-if="classiEcho(record.classify) === '7'">费用支出</div>
						</template>
						<template v-if="column.key === 'stage'">
							<div v-if="record.stage === 0">基本信息</div>
							<div v-if="record.stage === 1">建设用地储备</div>
							<div v-if="record.stage === 2">项目立项</div>
							<div v-if="record.stage === 3">项目招标</div>
							<div v-if="record.stage === 4">项目施工</div>
							<div v-if="record.stage === 5">竣工验收</div>
						</template>
						<template v-if="column.key === 'name'">
							<!-- <a-button type="link" size="small" @click="onDetail(record)">{{record.name}}</a-button> -->
							<div style="color: #1890ff;" @click="onDetail(record)">{{record.name}}</div>
						</template>
						<!-- <template v-if="column.key === 'schedule'">
							{{(record.schedule)*100+'%'}}
						</template> -->
						<template v-if="column.key === 'schedulePercent'">
						    <!-- {{ (Number(record.inviteTendersSuccessPrice) ? Math.floor((Number(record.constructionSchedulePercent?record.constructionSchedulePercent:0) / Number(record.inviteTendersSuccessPrice)) * 100) : 0)}}% -->
							<!-- {{ finishPercentage(record) ? Number(finishPercentage(record)).toFixed(2) : '0.00' }}% -->
							{{record.schedulePercent ? record.schedulePercent : '0.00%'}}
						</template>
						<template v-if="column.key === 'projectContractAmount'">
							<!-- {{onMount(record)}} -->
							{{record.projectContractAmount}}
						</template>
						<template v-if="column.key === 'designAmount'">
							<!-- {{onAllocated(record.projectApprovalSpeedDesignerPayment)}} -->
							{{record.designAmount}}
						</template>
						<template v-if="column.key === 'watchAmount'">
							<!-- {{onAllocated(record.inviteTendersWatcherPayment)}} -->
							{{record.watchAmount}}
						</template>
						<template v-if="column.key === 'saf'">
							<!-- {{onAllocated(record.inviteTendersSafPayment)}} -->
							{{record.safAmount}}
						</template>
						<template v-if="column.key === 'other'">
							<!-- {{otherAllocated(record)}} -->
							{{record.otherAmount}}
						</template>
						<template v-if="column.key === 'projectAmount'">
							<!-- <div v-if="record.inviteTendersSuccessPrice">
								{{record.inviteTendersSuccessPrice === ''? 0:record.inviteTendersSuccessPrice}}
							</div>
							<div v-else>0</div> -->
							<!-- {{Number(record.invite_real_price)}} -->
							{{record.projectAmount}}
						</template>
						<template v-if="column.key === 'buildLandSpeedCompletedPercent'">
							<!-- <div v-if="record.buildLandSpeed">
								{{countSpeed(record.buildLandSpeed,'completed')}}
							</div>
							<div v-else>0</div> -->
							{{record.buildLandSpeedCompletedPercent}}
						</template>
						<template v-if="column.key === 'paymentPercentage'">
							<div v-if="record.buildLandSpeed">
								<!-- {{countSpeed(record.buildLandSpeed,'completedPercent')}} -->
								{{record.paymentPercentage}}
								<!-- {{record.paymentPercentage === ''? 0: Number(record.paymentPercentage).toFixed(2)}}% -->
							</div>
							<div v-else>0.00%</div>
						</template>
						<template v-if="column.key === 'projectApprovalSourceSourceCost'">
							<div v-if="record.projectApprovalSourceSourceCost">
								{{record.projectApprovalSourceSourceCost === ''? 0:record.projectApprovalSourceSourceCost}}
							</div>
							<div v-else>0</div>
						</template>
						<template v-if="column.key === 'projectApprovalSourceOtherCost'">
							<div v-if="record.projectApprovalSourceOtherCost">
								{{record.projectApprovalSourceOtherCost === ''? 0:record.projectApprovalSourceOtherCost}}
							</div>
							<div v-else>0</div>
						</template>
						<template v-if="column.key === 'projectApprovalSourceCompanyCost'">
							<div v-if="record.projectApprovalSourceCompanyCost">
								{{record.projectApprovalSourceCompanyCost === ''? 0:record.projectApprovalSourceCompanyCost}}
							</div>
							<div v-else>0</div>
						</template>
						<template v-if="column.key === 'projectApprovalSourceSpecialCost'">
							<div v-if="record.projectApprovalSourceSpecialCost">
								{{record.projectApprovalSourceSpecialCost === ''? 0:record.projectApprovalSourceSpecialCost}}
							</div>
							<div v-else>0</div>
						</template>
						<template v-if="column.key === 'inviteTendersWatcherPrice'">
							<div v-if="record.inviteTendersWatcherPrice">
								{{record.inviteTendersWatcherPrice  === ''? 0:record.inviteTendersWatcherPrice }}
							</div>
							<div v-else>0</div>
						</template>
						<template v-if="column.key === 'inviteTendersFlowablePrice'">
							<div v-if="record.inviteTendersFlowablePrice">
								{{record.inviteTendersFlowablePrice  === ''? 0:record.inviteTendersFlowablePrice }}
							</div>
							<div v-else>0</div>
						</template>
						<template v-if="column.key === 'inviteTendersSafPrice'">
							<div v-if="record.inviteTendersSafPrice">
								{{record.inviteTendersSafPrice === ''? 0:record.inviteTendersSafPrice}}
							</div>
							<div v-else>0</div>
						</template>
						<template v-if="column.key === 'constructionSchedulePercent'">
							<div v-if="record.constructionSchedulePercent">
								{{record.constructionSchedulePercent === ''? 0:record.constructionSchedulePercent}}
							</div>
							<div v-else>0</div>
						</template>
						<template v-if="column.key === 'projectApprovalReviewTotalCost'">
							<div v-if="record.projectApprovalReviewTotalCost">
								{{record.projectApprovalReviewTotalCost === ''? 0:record.projectApprovalReviewTotalCost}}
							</div>
							<div v-else>0</div>
						</template>
						<template v-if="column.key === 'completionAccountsCost'">
							<div v-if="record.completionAccountsCost">
								{{record.completionAccountsCost === ''? 0:record.completionAccountsCost}}
							</div>
							<div v-else>0</div>
						</template>
						<template v-if="column.key === 'constructionScheduleContract'">
							<div v-if="record.inviteTendersSuccessPrice">
								<!-- {{countAuditPrice(record)}} -->
								{{record.invite_real_price}}
							</div>
							<div v-else>0</div>
						</template>
						<template v-if="column.key === 'constructionChangeAddAmount'">
							<div v-if="record.constructionChangeAddAmount">
								{{record.constructionChangeAddAmount === ''? 0:record.constructionChangeAddAmount}}
							</div>
							<div v-else>0</div>
						</template>
						<template v-if="column.key === 'pendingPayment'">
							<!-- {{waitAllocated(record)}} -->
							{{record.pendingPayment}}
						</template>
						
						<template v-if="column.key === 'boot_time'">
							{{ transDateTime(record.boot_time,1) }}
						</template>
						<template v-if="column.key === 'construction_company'">
						    {{ record.construction_company.length === 0?'/':record.construction_company}}
						</template>
						<template v-if="column.key === 'inviteTendersSuccessCompany'">
							<div v-if="record.inviteTendersSuccessCompany">
								{{record.inviteTendersSuccessCompany === ''? '/':record.inviteTendersSuccessCompany}}
							</div>
							<div v-else>/</div>
						</template>
						<template v-if="column.key === 'inviteTendersWatcherCompany'">
							<div v-if="record.inviteTendersWatcherCompany">
								{{record.inviteTendersWatcherCompany === ''? '/':record.inviteTendersWatcherCompany}}
							</div>
							<div v-else>/</div>
						</template>
						<template v-if="column.key === 'inviteTendersFlowableCompany'">
							<div v-if="record.inviteTendersFlowableCompany">
								{{record.inviteTendersFlowableCompany === ''? '/':record.inviteTendersFlowableCompany}}
							</div>
							<div v-else>/</div>
						</template>
						<template v-if="column.key === 'inviteTendersSafCompany'">
							<div v-if="record.inviteTendersSafCompany">
								{{record.inviteTendersSafCompany === ''? '/':record.inviteTendersSafCompany}}
							</div>
							<div v-else>/</div>
						</template>
						<template v-if="column.key === 'constructionInquiryCompany'">
							<div v-if="record.constructionInquiryCompany">
								{{record.constructionInquiryCompany === ''? '/':record.constructionInquiryCompany}}
							</div>
							<div v-else>/</div>
						</template>
						<template v-if="column.key === 'constructionAuditCompany'">
							<div v-if="record.constructionAuditCompany">
								{{record.constructionAuditCompany === ''? '/':record.constructionAuditCompany}}
							</div>
							<div v-else>
								{{record.inviteTendersFlowableCompany === ''? '/':record.inviteTendersFlowableCompany}}
							</div>
						</template>
						<template v-if="column.key === 'buildLandSourceType'">
							{{record.buildLandSourceType}}
						    <!-- <div v-if="record.buildLandSourceType === '0'">乡镇征收</div>
							<div v-if="record.buildLandSourceType === '1'">自然资源储备中心</div>
							<div v-if="record.buildLandSourceType === '2'">从单位或公司划拨</div>
							<div v-if="record.buildLandSourceType === undefined ">/</div> -->
						</template>
						<template v-if="column.key === 'buildLandNatureHasForestry'">
						    <div v-if="record.buildLandNatureHasForestry === '0'">涉及林业用地</div>
							<div v-if="record.buildLandNatureHasForestry === '1'">不涉及林业用地</div>
							<div v-if="record.buildLandNatureHasForestry === undefined ">/</div>
						</template>
						<template v-if="column.key === 'buildLandGainType'">
						    <div v-if="record.buildLandGainType === '0'">摘牌</div>
							<div v-if="record.buildLandGainType === '1'">有偿划拨</div>
							<div v-if="record.buildLandGainType === '2'">无偿划拨</div>
							<div v-if="record.buildLandGainType === '3'">协议转让</div>
							<div v-if="record.buildLandGainType === undefined " >/</div>
						</template>
						<template v-if="column.key === 'buildLandRegisterStatus'">
						    <div v-if="record.buildLandRegisterStatus === '0'">已办理</div>
							<div v-if="record.buildLandRegisterStatus === '1'">未办理</div>
							<div v-if="record.buildLandRegisterStatus === '2'">不需要</div>
							<div v-if="record.buildLandRegisterStatus === undefined ">/</div>
						</template>
						<template v-if="column.key === 'buildLandCostAuditStatus'">
						    <div v-if="record.buildLandCostAuditStatus ==='0'">有审计</div>
							<div v-if="record.buildLandCostAuditStatus === '1'">无审计</div>
							<div v-if="record.buildLandCostAuditStatus === '2'">不需要</div>
							<div v-if="record.buildLandCostAuditStatus === undefined">/</div>
							<!-- <div v-else>/</div> -->
						</template>
						<template v-if="column.key === 'buildLandSpeedStatus'">
						    <div v-if="record.buildLandSpeedStatus === '0'">有支出</div>
							<div v-if="record.buildLandSpeedStatus === '1'">无支出</div>
							<div v-if="record.buildLandSpeedStatus === '2'">不需要</div>
							<div v-if="record.buildLandSpeedStatus === undefined">/</div>
						</template>
						<template v-if="column.key === 'buildLandResultStatus'">
						    <div v-if="record.buildLandResultStatus === '0'">有调查</div>
							<div v-if="record.buildLandResultStatus === '1'">无调查</div>
							<div v-if="record.buildLandResultStatus === '2'">不需要</div>
							<div v-if="record.buildLandResultStatus === undefined">/</div>
						</template>
						<template v-if="column.key === 'projectApprovalHasFeasibility'">
						    <div v-if="record.projectApprovalHasFeasibility === '0'">有可研</div>
							<div v-if="record.projectApprovalHasFeasibility === '1'">无可研</div>
							<div v-if="record.projectApprovalHasFeasibility === '2'">不需要</div>
							<div v-if="record.projectApprovalHasFeasibility === undefined">/</div>
						</template>
						<template v-if="column.key === 'projectApprovalHasRisk'">
						    <div v-if="record.projectApprovalHasRisk === '0'">有备案表</div>
							<div v-if="record.projectApprovalHasRisk === '1'">无备案表</div>
							<div v-if="record.projectApprovalHasRisk === '2'">不需要</div>
							<div v-if="record.projectApprovalHasRisk === undefined">/</div>
						</template>
						<template v-if="column.key === 'projectApprovalHasDesign'">
						    <div v-if="record.projectApprovalHasDesign === '0'">有初设批复</div>
							<div v-if="record.projectApprovalHasDesign === '1'">无初设批复</div>
							<div v-if="record.projectApprovalHasDesign === '2'">不需要</div>
							<div v-if="record.projectApprovalHasDesign === undefined">/</div>
						</template>
						<template v-if="column.key === 'projectApprovalHasEnv'">
						    <div v-if="record.projectApprovalHasEnv === '0'">有环评</div>
							<div v-if="record.projectApprovalHasEnv === '1'">无环评</div>
							<div v-if="record.projectApprovalHasEnv === '2'">不需要</div>
							<div v-if="record.projectApprovalHasEnv === undefined">/</div>
						</template>
						<template v-if="column.key === 'projectApprovalHasKeep'">
						    <div v-if="record.projectApprovalHasKeep === '0'">有水保方案</div>
							<div v-if="record.projectApprovalHasKeep === '1'">无水保方案</div>
							<div v-if="record.projectApprovalHasKeep === '2'">不需要</div>
							<div v-if="record.projectApprovalHasKeep === undefined">/</div>
						</template>
						<template v-if="column.key === 'projectApprovalHasEvaluate'">
						    <div v-if="record.projectApprovalHasEvaluate === '0'">有防洪评价</div>
							<div v-if="record.projectApprovalHasEvaluate === '1'">无防洪评价</div>
							<div v-if="record.projectApprovalHasEvaluate === '2'">不需要</div>
							<div v-if="record.projectApprovalHasEvaluate === undefined">/</div>
						</template>
						<template v-if="column.key === 'projectApprovalHasEc'">
						    <div v-if="record.projectApprovalHasEc === '0'">有节能审批</div>
							<div v-if="record.projectApprovalHasEc === '1'">无节能审批</div>
							<div v-if="record.projectApprovalHasEc === '2'">不需要</div>
							<div v-if="record.projectApprovalHasEc === undefined">/</div>
						</template>
						<template v-if="column.key === 'projectApprovalHasFire'">
						    <div v-if="record.projectApprovalHasFire === '0'">有消防审批</div>
							<div v-if="record.projectApprovalHasFire === '1'">无消防审批</div>
							<div v-if="record.projectApprovalHasFire === '2'">不需要</div>
							<div v-if="record.projectApprovalHasFire === undefined">/</div>
						</template>
						<template v-if="column.key === 'projectApprovalHasPad'">
						    <div v-if="record.projectApprovalHasPad === '0'">有人防审批</div>
							<div v-if="record.projectApprovalHasPad === '1'">无人防审批</div>
							<div v-if="record.projectApprovalHasPad === '2'">不需要</div>
							<div v-if="record.projectApprovalHasPad === undefined">/</div>
						</template>
						<template v-if="column.key === 'projectApprovalReviewStatus'">
						    <div v-if="record.projectApprovalReviewStatus === '0'">已立项</div>
							<div v-if="record.projectApprovalReviewStatus === '1'">未立项</div>
							<div v-if="record.projectApprovalReviewStatus === '2'">不需要</div>
							<div v-if="record.projectApprovalReviewStatus === undefined">/</div>
						</template>
						<template v-if="column.key === 'constructionHasPermit'">
						    <div v-if="record.constructionHasPermit === '0'">有许可证或开工报告</div>
							<div v-if="record.constructionHasPermit === '1'">无许可证或开工报告</div>
							<div v-if="record.constructionHasPermit === undefined">/</div>
						</template>
						<template v-if="column.key === 'constructionHasChange'">
						    <div v-if="record.constructionHasChange === '0'">有变更</div>
							<div v-if="record.constructionHasChange === '1'">无变更</div>
							<div v-if="record.constructionHasChange === undefined">/</div>
						</template>
						<template v-if="column.key === 'constructionHasInquiry'">
						    <div v-if="record.constructionHasInquiry === '0'">有设备询价</div>
							<div v-if="record.constructionHasInquiry === '1'">有设备询价(无询价公司)</div>
							<div v-if="record.constructionHasInquiry === '2'">无设备询价</div>
							<div v-if="record.constructionHasInquiry === undefined">/</div>
						</template>
						<template v-if="column.key === 'progressPercent'">
							<!-- {{ finishPercentage(record) ? Number(finishPercentage(record)).toFixed(2) : '0.00' }}% -->
							{{record.buildSchedule}}
						</template>
						<template v-if="column.key === 'constructionSchedulePayPercent'">
						    {{record.constructionSchedulePayPercent?record.constructionSchedulePayPercent*100:0}}%
						</template>
						<template v-if="column.key === 'constructionHasAudit'">
						    <div v-if="record.constructionHasAudit === '0'">有审计</div>
							<div v-if="record.constructionHasAudit === '1'">有审计(跟踪审计)</div>
							<div v-if="record.constructionHasAudit === '2'">无审计</div>
							<div v-if="record.constructionHasAudit === '3'">不需要</div>
							<div v-if="record.constructionHasAudit === undefined">/</div>
						</template>
						<template v-if="column.key === 'completionHasLpi'">
						    <div v-if="record.completionHasLpi === '0'">有避雷检查</div>
							<div v-if="record.completionHasLpi === '1'">无避雷检查</div>
							<div v-if="record.completionHasLpi === '2'">不需要</div>
							<div v-if="record.completionHasLpi === undefined">/</div>
						</template>
						<template v-if="column.key === 'completionHasFire'">
						    <div v-if="record.completionHasFire === '0'">有消防验收</div>
							<div v-if="record.completionHasFire === '1'">无消防验收</div>
							<div v-if="record.completionHasFire === '2'">不需要</div>
							<div v-if="record.completionHasFire === undefined">/</div>
						</template>
						<template v-if="column.key === 'completionHasBuild'">
						    <div v-if="record.completionHasBuild === '0'">有建设规划验收</div>
							<div v-if="record.completionHasBuild === '1'">无建设规划验收</div>
							<div v-if="record.completionHasBuild === '2'">不需要</div>
							<div v-if="record.completionHasBuild === undefined">/</div>
						</template>
						<template v-if="column.key === 'completionHasEia'">
						    <div v-if="record.completionHasEia === '0'">有环评验收</div>
							<div v-if="record.completionHasEia === '1'">无环评验收</div>
							<div v-if="record.completionHasEia === '2'">不需要</div>
							<div v-if="record.completionHasEia === undefined">/</div>
						</template>
						<template v-if="column.key === 'completionHasKeep'">
						    <div v-if="record.completionHasKeep === '0'">有水保验收</div>
							<div v-if="record.completionHasKeep === '1'">无建设规划验收</div>
							<div v-if="record.completionHasKeep === '2'">不需要</div>
							<div v-if="record.completionHasKeep === undefined">/</div>
						</template>
						<template v-if="column.key === 'completionHasFiling'">
						    <div v-if="record.completionHasFiling === '0'">有竣工验收备案</div>
							<div v-if="record.completionHasFiling === '1'">无竣工验收备案</div>
							<div v-if="record.completionHasFiling === '2'">不需要</div>
							<div v-if="record.completionHasFiling === undefined">/</div>
						</template>
						<template v-if="column.key === 'invest_cost'">
							{{record.invest_cost ? record.invest_cost : 0}}
						</template>
						<template v-if="column.key === 'overExpenditure'">
						    <!-- <div v-if="(record.projectApprovalReviewTotalCost - record.completionAccountsCost)<0" :class="{'ui-warnColor': (record.projectApprovalReviewTotalCost - record.completionAccountsCost)<0}">({{-(( record.projectApprovalReviewTotalCost || 0) - (record.completionAccountsCost || 0))}})</div>
							<div v-else>{{(( record.projectApprovalReviewTotalCost || 0) - (record.completionAccountsCost || 0))}}</div>
						   {{record.profitOrLoss}} -->
						   <div v-if="record.profitOrLoss >= 0">{{record.profitOrLoss}}</div>
						   <div v-else class="ui-warnColor">{{Math.abs(record.profitOrLoss)}}</div>
						</template>
						<template v-if="column.key === 'action'">
							<!--  -->
							<a-button type="link" v-permission="['pc_project_manger_enter']" size="small" @click="onWriteInfo(record)" :disabled="record.is_enter == 1">信息录入</a-button>
							<span></span>
							<!-- :disabled="record.usable !== 1" -->
							<a-button type="link" v-permission="['pc_project_manger_update']" size="small" @click="onUpdateInfo(record)" :disabled="record.usable !== 1">修改</a-button>
							<span></span>
							<a-button type="link" v-permission="['pc_project_manger_ureqeust']" size="small" @click="onApply(record)">修改申请</a-button>
							<span></span>
							<a-button v-permission="['pc_project_manger_delete']" type="link" size="small" danger @click="onDelete(record)">删除</a-button>
						</template>
					</template>
				</c-Table>
				<!-- <a-table rowKey="id" :columns="columns" :data-source="dataList" size="small" :pagination="pagination" bordered
					:customRow="customRow" @resizeColumn="handleResizeColumn" :scroll="{x:2500}"
					:row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">
					<template #headerCell="{ column }">
						<template v-if="column.key === 'index'">
							<div @click="onShowSelectModal">
								<Icon icon="SettingFilled"></Icon>
							</div>
						</template>
					</template>
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'index'">
							{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
						</template>
						<template v-if="column.key === 'name'">
							<a-button type="link" size="small" @click="onDetail(record)">{{record.name}}</a-button>
						</template>
						<template v-if="column.key === 'action'">
							<a-button type="link" size="small">信息录入</a-button>
							<a-button type="link" size="small">修改</a-button>
							<a-button type="link" size="small">修改申请</a-button>
						</template>
					</template>
				</a-table> -->
				<p>选中{{ selNum }}条记录，投资额总计{{ inputNumberFormat(totalAmount) }}元，已拨付数总计{{ inputNumberFormat(alreadyPay) }}元，待拨付数总计{{ inputNumberFormat(awaitToPay) }}元</p>
			</div>
		</a-spin>
		<selectModal ref="selectModalRef" @onReset="onReset" @newColums="newColums"></selectModal>
		<saveModal ref="saveModalRef" @onReset="onReset"></saveModal>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import cTable from '@/components/cTable/index.vue';
	import {
		getSearchListByUnit
	} from '@/service/modules/inventory.js';
	import {
		getMangerSearchList,
		getExecuteSearchList,
		getFiscalSearchList,
		postUpdateRequest,
		getSelectedField,
		setSelectedField,
		projectDelete
	} from '@/service/modules/project.js';
	import selectModal from '@/views/project/components/selectModal.vue';
	import saveModal from '@/views/project/components/saveModal.vue';
	import eventBus from '@/utils/eventBus.js';
	import exportModal from '@/components/exportModal/exportModal.vue';
	export default {
		components: {
			Icon,
			cTable,
			selectModal,
			saveModal,
			exportModal
		},
		data() {
			return {
				loading: false,
				isSearch: false,
				searchData: {},
				getDataFun: getMangerSearchList,
				clearTime: [], // 存储时间 用来清空重置
				selNum: 0,
				selList:[],
				selKeysList: [],
				totalAmount: 0,	// 投资总计
				// alreadyPay: 0,	// 已拨总计
				awaitToPay: 0, // 待拨总计
				searchInfo: {
					searchKey: '',
					searchPlace: '请选择区域'
				},
				formSearch: {},
				type: 'projectMessageExportStrategy', // 导出类型
				role: 1, // 导出角色
				condition: '3', // 导出条件
				selIdsData:{},
				columns: [
					{
					title: '序号',
					align: 'center',
					key: 'index',
					fixed: 'left',
					rowDrag: true,
					width: 50,
				}, {
					title: '主管单位',
					align: 'center',
					dataIndex: "mangerUnit",
					key: 'mangerUnit',
					width: 100,
					fixed: 'left',
					resizable: true,
					sorter: true
				}, {
					title: '项目名称',
					align: 'center',
					dataIndex: "name",
					key: 'name',
					width: 100,
					fixed: 'left',
					sorter: true,
					resizable: true,
				}, {
					title: '项目分类',
					align: 'center',
					key: "classify",
					fixed: 'left',
					// dataIndex: "classify",
					width: 80,
					resizable: true,
					sorter: true
				}, {
					title: '实施单位',
					align: 'center',
					fixed: 'left',
					dataIndex: "executeUnit",
					width: 100,
					resizable: true,
					sorter: true
				}, {
					title: '是否重大重点项目',
					align: 'center',
					fixed: 'left',
					key: 'is_important',
					dataIndex: "is_important",
					width: 100,
					resizable: true,
					sorter: true
				}, {
					title: '启动时间',
					align: 'center',
					fixed: 'left',
					key: 'boot_time',
					dataIndex: "boot_time",
					width: 80,
					resizable: true,
					sorter: true
				}, {
					title: '项目阶段',
					align: 'center',
					dataIndex: "stage",
					key: 'stage',
					width: 80,
					fixed: 'left',
					resizable: true,
					sorter: true
				}, {
					title: '施工单位',
					align: 'center',
					fixed: 'left',
					key: 'constructionCompany',
					// dataIndex: "constructionCompany",
					sorter: true,
					width: 80,
					resizable: true
				}, {
					title: '投资概算',
					align: 'center',
					width: 100,
					key: 'invest_cost',
					dataIndex: "invest_cost",
					resizable: true,
					sorter: true
				}, {
					title: '已签订合同金额',
					align: 'center',
					key: 'account',
					// dataIndex: "account1",
					width: 180,
					resizable: true,
					// sorter:{ compare: (a, b) => a.account1 - b.account1, multiple: 9}
					sorter: true
				}, {
					title: '已拨付款',
					align: 'center',
					children: [{
						title: '设计费',
						align: 'center',
						width: 180,
						key: 'design',
						// dataIndex: "design",
						resizable: true,
						// sorter:{ compare: (a, b) => a.design - b.design, multiple: 8 }
						sorter: true
					}, {
						title: '工程款',
						align: 'center',
						width: 180,
						key: 'inviteTendersPrice',
						// dataIndex: "inviteTendersPrice",
						resizable: true,
						sorter: true
					}, {
						title: '工程进度',
						align: 'center',
						key: 'schedule',
						dataIndex: "schedule",
						width: 180,
						resizable: true,
						sorter: true
					}, {
						title: '监理费',
						align: 'center',
						width: 180,
						// dataIndex: "supervision",
						resizable: true,
						key: 'watcher',
						sorter: true
						// sorter: {
						// 	compare: (a, b) => a.supervision - b.supervision,
						// 	multiple: 5
						// }
					}, {
						title: '全过程咨询费',
						align: 'center',
						width: 180,
						// dataIndex: "consulting",
						key: 'saf',
						resizable: true,
						sorter: true
						// sorter: {
						// 	compare: (a, b) => a.consulting - b.consulting,
						// 	multiple: 4
						// }
					}, {
						title: '其他',
						align: 'center',
						width: 80,
						// dataIndex: "other",
						key: 'other',
						resizable: true,
						sorter: true
						// sorter: {
						// 	compare: (a, b) => a.other - b.other,
						// 	multiple: 3
						// }
					}]
				}, {
					title: '待拨付款',
					align: 'center',
					width: 100,
					// dataIndex: "wait",
					key: 'wait',
					resizable: true,
					sorter: true
					// sorter: {
					// 	compare: (a, b) => a.wait - b.wait,
					// 	multiple: 1
					// }
				}, {
					title: '操作',
					key: 'action',
					width: 250,
					align: 'center',
					resizable: true,
					fixed:'right'
				}],
				columnsExpand: [{
					title: '序号',
					align: 'center',
					key: 'index',
					width: 80,
					resizable: true,
					rowDrag: true,
				}, {
					title: '项目名称',
					align: 'center',
					dataIndex: "name",
					key: 'name',
					width: 180,
					resizable: true,
					// sorter: ()=>this.compareFun('name')
					// sorter: {
					// 	compare: (a, b) => a.name - b.name,
					// 	multiple: 10
					// }
				}, {
					title: '项目分类',
					align: 'center',
					key: "time",
					dataIndex: "expirationTime",
					width: 180,
					resizable: true,
					// sorter: ()=>this.compareFun('time')
					// sorter: {
					// 	compare: (a, b) => a.expirationTime - b.expirationTime,
					// 	multiple: 10
					// }
				}, {
					title: '项目阶段',
					align: 'center',
					dataIndex: "informUrl",
					width: 180,
					resizable: true,
					// sorter: ()=>this.compareFun('informUrl')
					// sorter:{compare: (a, b) => a.expirationTime - b.expirationTime, multiple: 10 }
				}, {
					title: '立项批复文号',
					align: 'center',
					dataIndex: "isAllocation",
					width: 180,
					resizable: true,
					// sorter: ()=>this.compareFun('isAllocation')
					// sorter:{compare: (a, b) => a.expirationTime - b.expirationTime, multiple: 10 }
				}, {
					title: '是否招投标',
					align: 'center',
					width: 180,
					resizable: true,
					// sorter: ()=>this.compareFun('isAllocation')
				}, {
					title: '中标价(合同价)',
					align: 'center',
					width: 180,
					resizable: true,
					// sorter: ()=>this.compareFun('isAllocation')
					// sorter:{compare: (a, b) => a.expirationTime - b.expirationTime, multiple: 10 }
				}, {
					title: '其他费用(预估)',
					align: 'center',
					width: 180,
					resizable: true,
					// sorter:{compare: (a, b) => a.expirationTime - b.expirationTime, multiple: 10 }
				}, {
					title: '中标公司',
					align: 'center',
					width: 180,
					resizable: true,
					// sorter:{compare: (a, b) => a.expirationTime - b.expirationTime, multiple: 10 }
				}, {
					title: '预计交付时间',
					align: 'center',
					width: 180,
					resizable: true,
					// sorter:{compare: (a, b) => a.expirationTime - b.expirationTime, multiple: 10 }
				}, {
					title: '是否已投入使用未转固定资产',
					align: 'center',
					width: 180,
					resizable: true
				}, {
					title: '资金来源',
					children: [{
						title: '小计',
						align: 'center',
						width: 100,
						resizable: true,
						// sorter:{compare: (a, b) => a.expirationTime - b.expirationTime, multiple: 10 }
					}, {
						title: '上级专项资金',
						align: 'center',
						width: 150,
						resizable: true,
						// sorter:{compare: (a, b) => a.expirationTime - b.expirationTime, multiple: 10 }
					}, {
						title: '县国资公司',
						align: 'center',
						width: 200,
						resizable: true,
						// sorter:{compare: (a, b) => a.expirationTime - b.expirationTime, multiple: 10 }
					}, {
						title: '县本级财政资金',
						align: 'center',
						width: 150,
						resizable: true,
						// sorter:{compare: (a, b) => a.expirationTime - b.expirationTime, multiple: 10 }
					}, {
						title: '其他',
						align: 'center',
						width: 200,
						resizable: true,
						// sorter:{compare: (a, b) => a.expirationTime - b.expirationTime, multiple: 10 }
					}]
				}, {
					title: '工程进度',
					children: [{
						title: '完成量（元）',
						align: 'center',
						width: 140,
						resizable: true,
						// sorter:{compare: (a, b) => a.expirationTime - b.expirationTime, multiple: 10 }
					}, {
						title: '按80%比例应付金额',
						align: 'center',
						width: 100,
						resizable: true,
						// sorter:{compare: (a, b) => a.expirationTime - b.expirationTime, multiple: 10 }
					}]
				}, {
					title: '已拨付数',
					children: [{
						title: '小计',
						align: 'center',
						width: 100,
						resizable: true,
						// sorter:{compare: (a, b) => a.expirationTime - b.expirationTime, multiple: 10 }
					}, {
						title: '上级专项资金',
						align: 'center',
						width: 100,
						resizable: true,
						// sorter:{compare: (a, b) => a.expirationTime - b.expirationTime, multiple: 10 }
					}, {
						title: '县国资公司',
						align: 'center',
						width: 100,
						resizable: true,
						// sorter:{compare: (a, b) => a.expirationTime - b.expirationTime, multiple: 10 }
					}, {
						title: '县本级财政资金',
						align: 'center',
						width: 160,
						resizable: true,
						// sorter:{compare: (a, b) => a.expirationTime - b.expirationTime, multiple: 10 }
					}, {
						title: '其他',
						align: 'center',
						width: 100,
						resizable: true,
						// sorter:{compare: (a, b) => a.expirationTime - b.expirationTime, multiple: 10 }
					}]
				}, {
					title: '是否审计结算',
					align: 'center',
					width: 180,
					resizable: true,
					// sorter:{compare: (a, b) => a.expirationTime - b.expirationTime, multiple: 10 }
				}, {
					title: '审计结算金额',
					align: 'center',
					width: 180,
					resizable: true,
					// sorter:{compare: (a, b) => a.expirationTime - b.expirationTime, multiple: 10 }
				}, {
					title: '尚需资金数',
					align: 'center',
					width: 180,
					resizable: true,
					// sorter:{compare: (a, b) => a.expirationTime - b.expirationTime, multiple: 10 }
				}, {
					title: '项目负责人',
					children: [{
						title: '姓名',
						align: 'center',
						width: 100,
						resizable: true,
						// sorter:{compare: (a, b) => a.expirationTime - b.expirationTime, multiple: 10 }
					}, {
						title: '电话',
						align: 'center',
						width: 100,
						resizable: true,
						// sorter:{compare: (a, b) => a.expirationTime - b.expirationTime, multiple: 10 }
					}]
				}, {
					title: '项目分管领导',
					children: [{
						title: '姓名',
						align: 'center',
						width: 100,
						resizable: true,
						// sorter:{compare: (a, b) => a.expirationTime - b.expirationTime, multiple: 10 }
					}, {
						title: '电话',
						align: 'center',
						width: 100,
						resizable: true,
						// sorter:{compare: (a, b) => a.expirationTime - b.expirationTime, multiple: 10 }
					}]
				}, {
					title: '操作',
					align: 'center',
					width: 200,
					key: 'action',
					resizable: true,
					// sorter:{compare: (a, b) => a.expirationTime - b.expirationTime, multiple: 10 }
				}],
				options: [{
						value: "1",
						label: '固定资产',
						children: []
					},
					{
						value: "2",
						label: '公共基础设施',
						children: [{
							value: "3",
							label: '水利'
						}, {
							value: "4",
							label: '交通'
						}, {
							value: "5",
							label: '市政'
						}, {
							value: "6",
							label: '其它'
						}],
					},
					{
						value: "7",
						label: '费用支出',
						children: []
					}
				],
				sourceIndex: -1,
				getSearchListByUnit: getSearchListByUnit,
				selectedRowKeys: [],
				auditAllCost: 0,
				startSchedule:null,
				endSchedule:null
			}
		},
		mounted() {
			this.onSearch();
			// eventBus.$on('refreshProject',()=>{
			// 	this.onReset();
			// })
		},
		computed: {
			alreadyPay() {
				return this.totalAmount - this.awaitToPay
			},
		},
		methods: {
			onSearchBtn() {
				this.isSearch = !this.isSearch;
			},
			onReset() {
				// this.$refs.formRef.resetFields();
				this.formSearch = {}
				this.clearTime = [];
				this.startSchedule = null;
				this.endSchedule = null;
				this.onSearch();
			},
			async getData() {

			},
			onSearch() {
				if(this.startSchedule && this.endSchedule){
					this.formSearch.startSchedule = this.startSchedule * 0.01;
					this.formSearch.endSchedule = this.endSchedule * 0.01;
				}
				this.formSearch.isMyExecute = 1;
				this.selIdsData.isMyExecute = 1;
				this.searchData = JSON.parse(JSON.stringify(this.formSearch));
				// this.pagination.current = 1;
				this.$nextTick(() => {
					this.$refs.cTable.toQuery();
				})
			},
			onDelete(item) {
				this.$confirm({
					title: '提示',
					content: "您正在删除在建工程项目，删除后数据将不可恢复，请确定是否删除？",
					onOk: async ()=> {
						this.loading = true;
						try {
							let ret = await projectDelete({
								id: item.id
							})
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('删除成功');
								this.onSearch()
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
			classiEcho(arr) {
				let tempArr = JSON.parse(arr)
				let len = tempArr.length
				return tempArr[len - 1]
			},
			onSort() {
				this.isSort = !this.isSort;
			},
			onTask() {
				this.$refs.saveModalRef.open();
			},
			onExport() {
				// this.$refs.resultModalRef.open()
			},
			onFold() {

			},
			handleResizeColumn(w, col) {
				col.width = w;
			},
			customRow(record, index) {
				return {
					draggable: true,
					style: {
						cursor: 'pointer'
					},
					onDragstart: (event) => {
						// 兼容IE
						var ev = event || window.event
						// 阻止冒泡
						ev.stopPropagation()
						// 得到源目标数据
						this.sourceIndex = index;
					},
					onDragover: (event) => {
						// 兼容 IE
						var ev = event || window.event
						// 阻止默认行为
						ev.preventDefault()
					},
					onDrop: (event) => {
						// 兼容IE
						var ev = event || window.event
						// 阻止冒泡
						ev.stopPropagation()
						// 得到目标数据
						// this.targetObj = record;
						let itemData = this.list.splice(this.sourceIndex, 1);
						this.list.splice(index, 0, itemData[0]);
					}
				}
			},
			onDetail(item) {
				// this.$refs.saveModalRef.open(item)
				this.$router.push({
					name: 'projectPreview',
					query: {
						id: item.id,
						// stage: item.stage,
						// isPreview: true,
					}
				})
			},
			onWriteInfo(item) { // 录入
				this.$router.push({
					name: 'projectRecord',
					query: {
						id: item.id,
						stage: item.stage,
					}
				})
			},
			onUpdateInfo(item) { // 修改
				this.$router.push({
					name: 'projectRecord',
					query: {
						id: item.id,
						stage: item.stage,
						usable: item.usable
					}
				})
			},
			async onApply(item) { //	修改申请
				this.loading = true
				try {
					let ret = await postUpdateRequest({
						projectId: item.id
					})
					if (ret.code === 200) {
						this.loading = false
						this.$message.success('申请成功');
						this.onSearch()
					}else{
						this.loading = false;
					}
				} catch (e) {
					//TODO handle the exception
					this.loading = false
				}
			},
			onShowSelectModal() {
				this.$refs.selectModalRef.open()
				// this.$refs.selectModalRef.open()
			},
			// onSelectChange(selectedRowKeys) {
			// 	this.selectedRowKeys = selectedRowKeys;
			// 	this.selectedLength = selectedRowKeys.length;
			// 	// console.log('selectedRowKeys changed: ', selectedRowKeys);
			// },
			rowSelected(item) {
				this.totalAmount = 0
				this.awaitToPay = 0
				this.selNum = item.keys.length
				this.selKeysList = item.keys
				this.selList = item.list
				this.selList.forEach(item => {
					this.onMount(item,'sum')
					this.waitAllocated(item,'sum')
				})
				this.selIdsData.ids = item.keys.join(',')
			},
			newColums(list) {
				this.columns = [];
				this.$nextTick(() => {
					this.columns = list;
				})
			},
			onChangeFun(date, dateString) { // 时间变化的回调
				this.clearTime = date
				let starDate = new Date(dateString[0])
				let endDate = new Date(dateString[1])
				// console.log(starDate.valueOf(), endDate.valueOf());
				this.formSearch.startTime = starDate.valueOf()/1000
				this.formSearch.endTime = endDate.valueOf()/1000
			},
			onGetPayment(val){
				let auditProjectPayList = val?.constructionPayment ? JSON.parse(val?.constructionPayment) : [];
				let flag = false;
				let auditProjectPay = 0;
				if(auditProjectPayList.length != 0){
					auditProjectPayList.forEach((item)=>{
						// 有审计金额
						if(item.auditCost){
							auditProjectPay += item.auditCost;
							flag = true;
						}
					})
				}
				// 有审计金额，返回审计金额
				if(flag&&Number(val.inviteTendersSuccessPrice)){
					return Number(auditProjectPay)
				}
			},
			speedMount(totalCost, payment) { // 三通一平审计 合同金额
				let jsPayment = (typeof payment === 'string') ? JSON.parse(payment) : payment
				let auditCost = 0
				if (jsPayment) {
					jsPayment.forEach(item => {
						auditCost += (Number(item.auditCost) || 0)
					})
					if (auditCost) {
						return Number(auditCost)
					}
				}
				return Number(totalCost) || 0;
			},
			speedCount(payment){//三通一平支出，合同金额
				let jsPayment = (typeof payment === 'string') ? JSON.parse(payment) : payment
				let allPayment = 0;
				if(jsPayment){
					jsPayment.forEach(item=>{
						allPayment += (item.cost || 0);
					})
				}
				if(allPayment){
					return allPayment;
				}
				return 0;
			},
			completionOtherFun(arrItem) { // 竣工验收 第三方费用
				let otherCost = 0;
				if (arrItem) {
					let jsOther = JSON.parse(arrItem)
					jsOther.forEach(item => {
						if(item.cost > 0){
							otherCost += this.speedPayment(item.payment)
						}
					})
				}
				return otherCost
			},
			approvalSpeedOtherFun(arrItem) { // 项目立项中介费用
				let jsOther
				let otherCost = 0;
				if(arrItem){
					jsOther = JSON.parse(arrItem);
				}
				if (jsOther && jsOther[0].service) {
					jsOther.forEach(item => {
						otherCost += this.speedPayment(item.payment)
					})
					
				}
				return otherCost
			},
			// 三通一平审计支付记录总额
			speedPayment(payment) { // 三通一平 或其它四项已拨
				let jsPayment = (typeof payment === 'string') ? JSON.parse(payment) : payment
				let otherCost = 0
				if (jsPayment) {
					jsPayment.forEach(item => {
						if(Array.isArray(item)) {
							item.forEach(itemChild => {
								if(itemChild.amount){
									otherCost += itemChild.amount
								}
							})
						} else {
							if(item.amount){
								otherCost += item.amount
							}
							
						}
					})
				}
				return otherCost
			},
			// 三通一平支出支付记录总额
			speedCountPayment(payment){
				let otherMount = 0
				if(payment){
					let jsPayment = (typeof payment === 'string') ? JSON.parse(payment) : payment
					if(jsPayment){
					jsPayment.forEach(item=>{
						item.payment.forEach(devItem=>{
							otherMount += devItem.amount
						})
					})
				 }
				}
				return otherMount
			},
			speedCountAuditCost(payment){//三通一平 计算审计金额,return 审计+合同，金额
				let jsPayment = (typeof payment === 'string') ? JSON.parse(payment) : payment;
				let flag = false;//是否有审计金额
				let auditAll = 0;
				if(jsPayment){
					jsPayment.forEach(item=>{
						let flagItem = false;//单个list是否有审计金额
						item.payment.forEach(devItem=>{
							if(devItem.type == 1 && devItem.auditCost){
								flag = true;
								flagItem = true;
								auditAll = auditAll + (devItem?.auditCost || 0);
							}
						})
						if(!flagItem){
							auditAll += (item?.cost) || 0;
						}
					})
				}
				// this.auditAllCost = auditAll;
				return auditAll;
			},
			onMount(tempObj,str) {	// 合同金额
				let buildLandCostAuditTotalCost = Number(tempObj.buildLandCostAuditTotalCost) || 0
				let buildLandResultTotalCost = Number(tempObj.buildLandResultTotalCost) || 0
				let buildLandSpeedTotalCost =  Number(tempObj.buildLandSpeedTotalCost) || 0
				let projectApprovalSpeedFeasibilityCost = Number(tempObj.projectApprovalSpeedFeasibilityCost) || 0
				let projectApprovalSpeedDesignerCost = Number(tempObj.projectApprovalSpeedDesignerCost) || 0
				let projectApprovalSpeedCheckerCost = Number(tempObj.projectApprovalSpeedCheckerCost) || 0
				let projectApprovalSpeedSurveyorCost = Number(tempObj.projectApprovalSpeedSurveyorCost) || 0
				let projectApprovalSpeedGeologicalCost = Number(tempObj.projectApprovalSpeedGeologicalCost) || 0
				let projectApprovalSpeedBudgetingCost = Number(tempObj.projectApprovalSpeedBudgetingCost) || 0
				let projectApprovalSpeedBudgetCost = Number(tempObj.projectApprovalSpeedBudgetCost) || 0
				let projectApprovalSpeedAgentCost = Number(tempObj.projectApprovalSpeedAgentCost) || 0
				let inviteTendersWatcherPrice = Number(tempObj.inviteTendersWatcherPrice) || 0
				let inviteTendersFlowablePrice = Number(tempObj.inviteTendersFlowablePrice) || 0
				let inviteTendersSafPrice = Number(tempObj.inviteTendersSafPrice) || 0
				let constructionInquiryCost =  Number(tempObj.constructionInquiryCost) || 0
				let constructionAuditCost = Number(tempObj.constructionAuditCost) || 0
				let inviteTendersSuccessPrice = Number(tempObj.inviteTendersSuccessPrice) || 0
				let buildLandRegisterTotalCost = Number(tempObj.buildLandRegisterTotalCost) || 0
				let buildLandGainTotalCost = Number(tempObj.buildLandGainTotalCost) || 0
				let buildLandApprovalCost = Number(tempObj.buildLandApprovalCost) || 0
				let buildLandNatureApprovalCost = Number(tempObj.buildLandNatureApprovalCost) || 0
				let buildLandNatureMapCost = Number(tempObj.buildLandNatureMapCost) || 0
				let buildLandSourceTotalCost = Number(tempObj.buildLandSourceTotalCost) || 0
				// ? this.auditAllCost : Number(this.speedCount(tempObj.buildLandSpeed))
				let buildLandSpeed = this.speedCountAuditCost(tempObj.buildLandSpeed)
				let completionOtherSum =  0
				let approvalSpeedOtherSum =  0
				if(tempObj.completionOther) {
					let completionOther = JSON.parse(tempObj.completionOther)
					completionOther.forEach(item =>{
						if(item?.cost){
							completionOtherSum += item.cost
						}
					})
				}
				if(tempObj.projectApprovalSpeedOther) {
					let projectApprovalSpeedOther = JSON.parse(tempObj.projectApprovalSpeedOther)
					projectApprovalSpeedOther.forEach(item =>{
						if(item?.cost){
							approvalSpeedOtherSum += item.cost
						}
						
					})
				}
				if(this.onGetPayment(tempObj)){
					inviteTendersSuccessPrice  = this.onGetPayment(tempObj)
				}
                if(tempObj.buildLandGainType === '0' || tempObj.buildLandGainType ==='1' || tempObj.buildLandGainType === '3'){
					buildLandSourceTotalCost = 0
					buildLandApprovalCost = 0
				}
				buildLandSpeedTotalCost = this.speedMount(tempObj?.buildLandSpeedTotalCost,tempObj?.buildLandSpeedPaymentFile)
				let mount = buildLandSourceTotalCost + 
							buildLandNatureMapCost + 
							buildLandNatureApprovalCost + 
							buildLandApprovalCost + 
							buildLandGainTotalCost + 
							buildLandRegisterTotalCost + 
							inviteTendersSuccessPrice + 
							approvalSpeedOtherSum + 
							completionOtherSum + 
							buildLandCostAuditTotalCost + 
							buildLandResultTotalCost + 
							buildLandSpeedTotalCost + 
							projectApprovalSpeedFeasibilityCost + 
							projectApprovalSpeedDesignerCost + 
							projectApprovalSpeedCheckerCost + 
							projectApprovalSpeedSurveyorCost + 
							projectApprovalSpeedGeologicalCost + 
							projectApprovalSpeedBudgetingCost + 
							projectApprovalSpeedBudgetCost + 
							projectApprovalSpeedAgentCost + 
							inviteTendersWatcherPrice + 
							inviteTendersFlowablePrice + 
							inviteTendersSafPrice + 
							constructionInquiryCost + 
							constructionAuditCost +
							buildLandSpeed
				if(str === 'sum') this.totalAmount += mount
				return mount
			},
			onAllocated(item) { // 已拨款(用于算待拨)
				let mount = 0
				if(item) {
					let tempItem = JSON.parse(item)
					tempItem.forEach(payment =>{
						mount += (payment.amount || 0)
					})
				}
				return mount
			},
			multiplePayment(item) { // 中介和聘请第三方的付款金额
				let mount = 0
				if(item) {
					let jsItem = JSON.parse(item)
					jsItem.forEach(item2 => {
						let	jsItem2 = item2.payment
						jsItem2.forEach(payment => {
							mount += payment.amount
						})
					})
				}
				return mount
			},
			otherAllocated(item) { // 其它已拨
				let otherMount = 0
				// 林地测绘费用+林地报批费用+出让金/支付资金/转让金+办证费用+
				otherMount += Number(item.buildLandNatureMapCost ? item.buildLandNatureMapCost : 0) +
							  Number(item.buildLandNatureApprovalCost ? item.buildLandNatureApprovalCost : 0) +
							  Number(item.buildLandGainTotalCost ? item.buildLandGainTotalCost : 0) +
							  Number(item.buildLandRegisterTotalCost ? item.buildLandRegisterTotalCost : 0) +
							  Number(this.speedCountPayment(item?.buildLandSpeed)) +
							  Number(this.speedPayment(item?.buildLandCostAuditPaymentFile)) +
							  Number(this.speedPayment(item?.buildLandResultPaymentFile)) +
							  Number(this.speedPayment(item?.projectApprovalSpeedFeasibilityPayment)) +
							  Number(this.speedPayment(item?.projectApprovalSpeedCheckerPayment)) +
							  Number(this.speedPayment(item?.projectApprovalSpeedSurveyorPayment)) +
							  Number(this.speedPayment(item?.projectApprovalSpeedGeologicalPayment)) +
							  Number(this.speedPayment(item?.projectApprovalSpeedBudgetingPayment)) +
							  Number(this.speedPayment(item?.projectApprovalSpeedBudgetPayment)) +
							  Number(this.speedPayment(item?.projectApprovalSpeedAgentPayment)) +
							  Number(this.approvalSpeedOtherFun(item?.projectApprovalSpeedOther)) +
							  Number(this.speedPayment(item?.inviteTendersFlowablePayment)) +
							  Number(this.speedPayment(item?.constructionInquiryPayment)) +
							  Number(this.speedPayment(item?.constructionAuditPayment)) +
							  Number(this.completionOtherFun(item?.completionOther))
				// otherMount += this.multiplePayment(item.completionOther) + this.multiplePayment(item.projectApprovalSpeedOther)
				// otherMount += this.onAllocated(item.buildLandSpeedPaymentFile) + this.onAllocated(item.buildLandCostAuditPaymentFile) + this.onAllocated(item.buildLandResultPaymentFile) + this.onAllocated(item.projectApprovalSpeedFeasibilityPayment) + this.onAllocated(item.projectApprovalSpeedCheckerPayment) + this.onAllocated(item.projectApprovalSpeedSurveyorPayment) + this.onAllocated(item.projectApprovalSpeedGeologicalPayment) + this.onAllocated(item.projectApprovalSpeedBudgetingPayment) + this.onAllocated(item.projectApprovalSpeedBudgetPayment) + this.onAllocated(item.projectApprovalSpeedAgentPayment) + this.onAllocated(item.inviteTendersFlowablePayment) + this.onAllocated(item.constructionInquiryPayment) + this.onAllocated(item.constructionAuditPayment) + this.onAllocated(item.constructionInquiryPayment)
				if(item.buildLandGainType === '2'){
					// 征拆资金+土地报批
					otherMount += Number(item.buildLandSourceTotalCost ? item.buildLandSourceTotalCost : 0) + 
					              Number(item.buildLandApprovalCost ? item.buildLandApprovalCost : 0)
				}
				if(isNaN(otherMount)) return 0;
				return otherMount
			},
			waitAllocated(item,str) { // 待拨
				// 合同金额-其他已拨-已拨款
				let mount = 0;
				// let inviteTendersSuccessPrice = item.inviteTendersSuccessPrice?(item.inviteTendersSuccessPrice.length === 0? 0:item.inviteTendersSuccessPrice.length):0
				// 工程中标价？（）
				mount = this.onMount(item) - Number(item.invite_real_price) - this.otherAllocated(item) - this.onAllocated(item.projectApprovalSpeedDesignerPayment) - this.onAllocated(item.inviteTendersWatcherPayment) - this.onAllocated(item.inviteTendersSafPayment)
				// mount = 合同金额 - 工程中标价 - 其他已拨 - 已拨款 - 
				if(str === 'sum') this.awaitToPay += mount
				return mount
			},
			// 排序
			changeSort(order,columnKey){
				const sort = order == 'ascend' ? 'asc' : order == 'descend' ? 'desc' : null;
				this.formSearch.sort = [{
					key:columnKey,
					sort
				}]
				if(!sort){
					delete this.formSearch.sort;
				}
				this.onSearch();
			},
			// 判断工程款是否有审计金额 ？ 审计金额 ：工程款
			auditProjectPay(value){
				let auditProjectPayList = value?.constructionPayment ? JSON.parse(value?.constructionPayment) : [];
				let flag = false;
				let auditProjectPay = 0;
				if(auditProjectPayList.length != 0){
					auditProjectPayList.forEach((item)=>{
						// 有审计金额
						if(item.auditCost){
							auditProjectPay += item.auditCost;
							flag = true;
						}
					})
				}
				// 无审计金额，返回0
				if(!flag){
					return 0;
				}
				return auditProjectPay;//返回审计金额
			},
			// 项目施工，工程进度，百分比计算  （已拨付款）
			finishPercentage(item) {
				let amount = 0;
				// 有审计金额
				// if(this.auditPay(item.constructionPayment)){
				// 	amount += Number(this.auditPay(item.constructionPayment));
				// }
				// 是否有审计金额
				amount += Number(item?.invite_real_price?item?.invite_real_price:0)
				// 有增量金额
				if(item.constructionChangeAddAmount){
					amount += Number(item.constructionChangeAddAmount);
				}
				// // 无审计金额
				// if(this.auditPay(item.constructionPayment)===0){
				// 	amount += Number(item.inviteTendersSuccessPrice);
				// }
				const res = (item.constructionSchedulePercent / amount) * 100;
				return res;
			},
			// 项目施工 审计金额
			auditPay(arr) {
				let amount = 0
				arr = arr?JSON.parse(arr):[]
				if (arr && arr.length) {
					arr.forEach(item => {
						if(item.auditCost) amount += item.auditCost
					})
				}
				if(isNaN(amount)) return 0;
				return amount
			},
			// 计算三通一平支出（完工量，完工进度）
			countSpeed(list,string){
				let allpetedPercent = 0;//工程完成量总和
				let allAuditOrCost = 0;//审计或者合同金额
				list = JSON.parse(list);
				if(Array.isArray(list)){
					list.forEach(item => { 
						let flag = false;
						allpetedPercent += (item?.completedPercent || 0 );
						item.payment.forEach(devitem => {
							if(devitem?.auditCost){
								flag = true;
								allAuditOrCost += devitem?.auditCost || 0;
							}
						})
						if(!flag){
							allAuditOrCost += item?.cost || 0;
						}
					})
					if(string == 'completed'){
						return allpetedPercent;
					}
					if(string == 'completedPercent'){
						if(allAuditOrCost == 0){
							return 0 + '%';
						}
						// console.log(allpetedPercent,allAuditOrCost);
						return ((allpetedPercent/allAuditOrCost) * 100).toFixed(2) + '%';
					}
				}
				return 0 ;
			},
			// 计算工程款，合同金额，是否有审计金额 ？ 审计金额 ： 合同金额
			countAuditPrice(obj){
				if(!obj.constructionPayment && !obj.inviteTendersSuccessPrice) return 0;
				let list = obj?.constructionPayment ? JSON.parse(obj.constructionPayment) : [];
				let contract = obj?.inviteTendersSuccessPrice ? Number(obj?.inviteTendersSuccessPrice) : 0;
				// let addPrice = obj?.constructionChangeAddAmount ? JSON.parse(obj?.constructionChangeAddAmount) : 0;
				// 判断工程款是否有审计金额
				let auditCost = 0;
				list.forEach(item => {
					if(item.type == 2 && item.auditCost){
						auditCost += item.auditCost;
					}
				})
				if(auditCost){
					return auditCost || 0;
				}else{
					return contract || 0;
				}
			}
		}
	}
</script>

<style scoped>
	.ui-form {
		margin-top: 20px;
		padding: 20px 20px 0;
		background: #F5F7FA;
	}
	.ui-warnColor {
		color: red;
	}
	.ui-exportBrn {
		margin-left: 16px;
	}
</style>
